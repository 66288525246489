<template>
    <section>
        <b-card>
            <b-row>
                <b-col cols="12">
                    <header class="d-flex justify-content-between align-items-center mb-2">
                        <h4 class="text-secondary"><strong>{{ bop.no }}</strong></h4>
                        
                    </header>
                    <div v-once>
                        <table class="table table-responsive table-borderless">
                            <tr>
                                <th v-if="!isSales">Nama Sales</th>
                                <th>Tanggal Ajuan</th>
                                <th>Nominal</th>
                                <th>Status Ajuan</th>
                                <th>Keterangan</th>
                            </tr>
                            <tr>
                                <td v-if="!isSales">{{ bop.sales ? bop.sales.nama_lengkap : '-' }}</td>
                                <td>
                                    {{ bop.tanggal }}
                                </td>
                                <td>
                                    Rp {{ formatRupiah(bop.nominal) }}
                                </td>
                                <td>
                                    <b-badge :variant="status.variant">
                                        {{ status.status }}
                                    </b-badge>
                                </td>
                                <td>{{ bop.keterangan }}</td>
                            </tr>
                        </table>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <div class="d-flex justify-content-end mb-2">
            <section>
                <div class="d-flex justify-content-end" v-if="isSPV">
                    <b-button v-if="status.kode == 1" variant="info" @click.prevent="confirmApprove2">Setujui Ajuan</b-button>
                    <b-button v-if="canCancel && status.kode != 4" variant="outline-danger" class="ml-2" @click.prevent="cancel">Tolak Ajuan</b-button>
                    <b-button v-if="status.kode == 4" variant="secondary" disabled>BOP telah diselesaikan</b-button>
                </div>
                <div class="d-flex justify-content-end" v-if="isSales">
                    <b-button variant="success" v-if="status.kode == 3" @click.prevent="confirmApprove2">Terima Uang</b-button>
                    <b-button v-else variant="secondary" disabled>Ajuan telah diselesaikan</b-button>
                </div>
                <div class="d-flex justify-content-end" v-if="isFinance">
                    <b-button variant="success" v-if="status.kode == 2" @click="confirmApprove()">Pilih Sumber Pengeluaran</b-button>
                    <b-button variant="danger" v-if="status.kode == 2" @click="confirmtolak()" class="ml-2">Tolak Ajuan</b-button>
                    <b-button v-if="status.kode == 4" variant="secondary" disabled>Ajuan telah diselesaikan</b-button>
                </div>
            </section>
        </div>
        <slot></slot>
        <b-modal
            id="kas-modal"
            v-model="showModalapprfinance"
            title="Pilih Akun & Kas"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Nominal BOP Sales">
                <h4>
                  <i class="text-success">
                    <strong>Rp {{ formatRupiah(bop.nominal) }}</strong>
                  </i>
                </h4>
              </b-form-group>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form2.id_akun" :options="id_akun" class="mb-1" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form2.id_kas" :options="id_kas" class="mb-1" />
                <i class="text-danger" v-if="invalidKas">Saldo Kas kurang dari nominal BOP Sales</i>
              </b-form-group>
            </b-form>
            <template #modal-footer>
              <b-button
                @click.prevent="approvereal"
                variant="primary"
                class="mr-1"
                :disabled="!isValidAkunForm"
              >
                Approve
              </b-button>
            </template>
          </b-modal>
    </section>
    
</template>
<script>
import {BCard, BRow, BCol, BBadge, BButton, BModal, BFormGroup,
  BFormSelect,} from 'bootstrap-vue'

export default {
    components: {
        BCard, BRow, BCol, BBadge, BButton, BModal, BFormGroup,
  BFormSelect,
    },
    props: ['bop'],
    watch: {
      bop(val) {
        this.getInfo()
      }
    },
    data: () => ({
        canCancel: false,
        id_akun:[],
        id_kas:[],
        formtolak: {
          tolak : 1,
        },
        form2: {
          id_kas: null,
          id_akun: null,
        },
        status: {
          variant: 'danger',
          status: 'Pending',
          kode: 1
        },
        showModalapprfinance: false,
    }),
    computed: {
      invalidAkun() {
        const akun = this.id_akun.find(item => item.id == this.form2.id_akun)
        if(!akun) return false

        return akun && (parseInt(akun.saldo) < 1 || parseInt(akun.saldo) < this.bop.nominal)
      },
      invalidKas() {
        const kas = this.id_kas.find(item => item.id == this.form2.id_kas)
        if(!kas) return false

        return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < this.bop.nominal)
      },
      isValidAkunForm() {
        if(!this.form2.id_akun || !this.form2.id_kas) {
          return false
        }

        // get selected akun and kas and validate saldo
        if(this.invalidKas) {
          return false
        }

        return true

      },
      statusess() {
          return this.$store.state.bop.status
      }
    },
    methods: {
        confirmtolak() {
      this.$swal({
        title: "Anda yakin?",
        text: "Ajuan ini ditolak",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          this.ditolak();
        }
      });
    },
    async ditolak() {
      if (this.$route.params.id) {
        this.formtolak.id = this.$route.params.id;
      }
      const payload = this.formtolak;
      try {
        const ajuan = await this.$store.dispatch("bop/approve", 
          [payload],
        );
        if (this.id) {
          // this.getStatusData();
          // this.refreshData()
                  await this.$emit('refreshData')
                  setTimeout(() => this.getInfo(), 1000)
        } else {
          // this.getStatusData();
          // this.refreshData()
                  await this.$emit('refreshData')
                  setTimeout(() => this.getInfo(), 1000)
          this.displaySuccess({
            message: "Ajuan sudah ditolak",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    confirmApprove() {
      this.showModalapprfinance = true;
      this.getKas();
      this.getakun();
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });

          this.id_akun = akun.filter(item => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(item.jenis))
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });

          this.id_kas = kas
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async approvereal() {
      if(!this.isValidAkunForm) {
        this.displayError({
          message: 'Harap lengkapi form pemilihan akun dengan benar!'
        })
        return false
      }

      if (this.$route.params.id) {
        this.form2.id = this.$route.params.id;
      }
      const payload = this.form2;
      payload.status = 3;
      try {
        this.$bvModal.hide('kas-modal')
        this.$emit('loading', true)
        
        const ajuan = await this.$store.dispatch("bop/approve", [payload]);
        const key = 'ajuan_bop'
        const value = {
          level: this.user.level.id,
          data: ajuan
        }

        this.eventSocket(key, value)
        this.displaySuccess({
          message: "BOP disetujui dan telah ditentukan akun",
        });
        await this.$emit('refreshData')
        this.$emit('loading', false)
        setTimeout(() => this.getInfo, 1000)
      } catch (e) {
        this.$emit('loading', false)
        this.$bvModal.show('kas-modal')
        this.displayError(e);
        return false;
      }
    },
        cancel() { // tolak spv
            this.$swal({
                title: "Anda yakin?",
                text: `Anda akan membatalkan ajuan BOP ini`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Batal",
                customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,    
            })
            .then(async res => {
                if(res.value) {
                    const payload = Object.assign({}, this.bop)
                    delete payload.status
                    if(this.isSPV) {
                        payload.appr_kirim = 2 // cancel spv
                        payload.status = 5
                    }
                    else {
                        payload.appr_finance = 2 // cancel finance
                        payload.status = 6
                    }

                    try {
                        this.loading = true
                        const bop = await this.$store.dispatch('bop/save', [payload])
                        const key = 'ajuan_bop'
                        const value = {
                          level: this.user.level.id,
                          data: bop
                        }

                        this.eventSocket(key, value)
                        this.displaySuccess({
                            message: 'Ajuan BOP telah dibatalkan'
                        })
                        setTimeout(() => this.$router.push('/bop'), 1000)
                        this.loading = false
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        async checkCancelPermission() {
            const parentMenu = await this.currentMenu(this.$route.meta.parent)
            const params = {level_id: this.user.level.id}
            if(parentMenu) params.menu_id = parentMenu.id
            const status = await this.$store.dispatch('statusrole/getData', params)
            const findCancel = status.find(st => st.data_status == 3)

            this.canCancel = findCancel ? true : false
        },
        async confirmApprove2() {
          const text = this.isSales ? 'Anda akan menyelesaikan ajuan bop ini' : 'Anda akan menyetujui ajuan BOP ini'
          this.$swal({
            title: 'Anda yakin?',
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false
          })
          .then(async res => {
            if(res.value) {
              
              if(this.isSPV) {
                this.bop.appr_kirim = 1
                this.bop.status = 2 // disetujui spv
                const key = 'ajuan_bop'
                const value = {
                  level: this.user.level.id,
                  data: this.bop
                }

                this.eventSocket(key, value)
              }
              if(this.isFinance) {
                this.bop.appr_finance = 1
                this.bop.status = 3
              }
              if(this.isSales && this.bop.appr_finance == 1) {
                this.bop.appr_terima = 1 // tandai sudah diterima oleh pengaju
                this.bop.status = 4 // diterima sales
                const key = 'ajuan_bop'
                const value = {
                  level: this.user.level.id,
                  data: this.bop
                }

                this.eventSocket(key, value)
              }
              try {
                  this.$emit('loading', true)
    
                  await this.$store.dispatch('bop/save', [this.bop])
    
                  this.$emit('loading', false)
                  this.displaySuccess({
                      message: this.isSales ? 'BOP Ditandai Selesai' : 'Ajuan BOP Disetujui'
                  })
                  await this.$emit('refreshData')
                  setTimeout(() => this.getInfo(), 1000)
              }
              catch(e) {
                  this.$emit('loading', false)
                  this.displayError(e)
                  return false
              }
            }
          })
            
        },
        getInfo() {
          this.status = null
          const result = {
            variant: 'danger',
            status: 'Pending',
            kode: 1
          }

            if(this.bop.appr_kirim == 0 && this.bop.appr_finance == 0 && this.bop.appr_terima == 0) {
              result.variant = 'danger'
              result.status = 'Pending'
              result.kode = 1
            }
            else if(this.bop.appr_kirim == 1 && this.bop.appr_finance == 0 && this.bop.appr_terima == 0) {
              result.variant = 'info'
              result.status = 'Disetujui SPV'
              result.kode = 2
            }
            else if(this.bop.appr_kirim == 1 && this.bop.appr_finance == 1 && this.bop.appr_terima == 0) {
              result.variant = 'warning'
              result.status = 'Disetujui Finance'
              result.kode = 3
            }
            else if(this.bop.appr_kirim == 1 && this.bop.appr_finance == 1 && this.bop.appr_terima == 1) {
              result.variant = 'success'
              result.status = 'Selesai'
              result.kode = 4
            }
            else if(this.bop.appr_kirim == 2 && this.bop.appr_finance != 2) {
              result.variant = 'dark'
              result.status = 'Ditoak SPV'
              result.kode = 5
            }
            else if(this.bop.appr_finance == 2) {
              result.variant = 'dark'
              result.status = 'Ditolak Finance'
              result.kode = 6
            }

            this.status = result
        },
        getStatusData(status_id) {
            const status = this.statusess.find(st => st.data_status == status_id)
            return this.statusess.find(st => st.data_status == status_id)
        },
        async getBOP() {
      if (this.isSales) {
        this.params.sales_id = this.user.karyawan.id;
      }
      const bops = await this.$store.dispatch("bop/getData", this.params);
      bops.map(bop => bop.status = this.getInfo(bop)) // for canceled finance
      const pendingBop = bops.filter(bop => bop.status.kode == 1)
      const bossBop = bops.filter(bop => bop.status.kode == 1)
      const approvedSpvBop = bops.filter(bop => bop.status.kode == 2)
      const approvedFinanceBop = bops.filter(bop => bop.status.kode == 3)
      const completeBop = bops.filter(bop => bop.status.kode == 4)
      const cancelSpvBop = bops.filter(bop => bop.status.kode == 5)
      const cancelFinanceBop = bops.filter(bop => bop.status.kode == 6)

      let finalBops = [...pendingBop, ...completeBop, ...approvedSpvBop, ...approvedFinanceBop, ...cancelSpvBop, ...cancelFinanceBop]
      if(this.isSales) {
        finalBops = [...approvedFinanceBop, ...completeBop, ...pendingBop, ...approvedSpvBop, ...cancelSpvBop, ...cancelFinanceBop]
      }
      else if(this.isSPV) {
        finalBops = [...pendingBop, ...completeBop, ...approvedSpvBop, ...approvedFinanceBop, ...cancelSpvBop, ...cancelFinanceBop]
      }
      else if(this.isFinance) {
        finalBops = [...approvedSpvBop, ...pendingBop, ...completeBop, ...approvedFinanceBop, ...cancelSpvBop, ...cancelFinanceBop]
      }
      this.bops = finalBops;
      this.totalRows = finalBops.length;
    },
    },
    created() {
      this.getInfo()
        this.checkCancelPermission()
    }
}
</script>