<template>
    <b-overlay :show="loading">
        <b-tabs :vertical="isVerticalTab" nav-wrapper-class="nav-vertical">
            <b-tab :active="titleTab" title="Info BOP">
                <bop-info @loading="doLoading" @refreshData="refreshData" :bop="bop">
                    <b-card v-if="isReimburse || isPengembalian && bop.appr_terima > 0">

                        <div >
                            <table class="table table-borderless">
                                <thead>
                                    <tr v-if="isFinance">
                                        <td>Bukti Penerimaan BOP</td>
                                        <td></td>
                                        <th>
                                            <a href="#" class="text-primary" @click.prevent="printBop()">
                                                <feather-icon icon="PrinterIcon" class="mr-1"></feather-icon>
                                                Cetak
                                            </a>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Total LPJ</td>
                                        <th colspan="2">Rp {{ formatRupiah(total) }}</th>
                                    </tr>
                                    <tr v-if="isReimburse && sudahSetor">
                                        <td>Total Reimburse</td>
                                        <th :colspan="isFinance ? 1 : 2">Rp {{ formatRupiah(sisa) }}</th>
                                        <th v-if="isFinance && sudahSetor">
                                            <a href="#" class="text-primary" @click.prevent="printSisa('reimburse')">
                                                <feather-icon icon="PrinterIcon"></feather-icon>
                                                Cetak
                                            </a>
                                            <a v-if="isFinance && bop.info.reimburse && bop.info.reimburse.id_akun == null" href="#" class="text-info mr-2" @click="confirmdireimburse">
                                                <feather-icon icon="LayersIcon" class="mr-1"></feather-icon>
                                                Tentukan Akun
                                            </a>
                                        </th>
                                    </tr>
                                    <tr v-if="isPengembalian">
                                        <td>Total Pengembalian</td>
                                        <th :colspan="isFinance ? 1 : 2">Rp {{ formatRupiah(sisa) }}</th>
                                        <th v-if="isFinance && sudahSetor">
                                            <a href="#" class="text-primary mr-2" @click.prevent="printSisa('pengembalian')">
                                                <feather-icon icon="PrinterIcon" class="mr-1"></feather-icon>
                                                Cetak
                                            </a>
                                            <a v-if="isFinance && bop.info.pengembalian && bop.info.pengembalian.id_akun == null" href="#" class="text-info mr-2" @click="confirmdikembali">
                                                <feather-icon icon="LayersIcon" class="mr-1"></feather-icon>
                                                Tentukan Akun
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <b-modal
            id="modal-select2"
            v-model="showModalapprfinancebukti"
            title="Pilih Akun & Kas (Bukti BOP)"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form.id_akun" :options="id_akun" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form.id_kas" :options="id_kas" />
              </b-form-group>
            </b-form>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                @click.prevent="bukti"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-modal>
                        <b-modal
            id="modal-select2"
            v-model="showModalapprfinance"
            title="Pilih Akun & Kas (Pengembalian)"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form2.id_akun" :options="id_akun" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form2.id_kas" :options="id_kas" />
              </b-form-group>
            </b-form>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                @click.prevent="dikembali"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-modal>
          <b-modal
            id="modal-select3"
            v-model="showModalapprfinance2"
            title="Pilih Akun & Kas (Reimburse)"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form3.id_akun" :options="id_akun" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form3.id_kas" :options="id_kas" />
              </b-form-group>
            </b-form>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                @click.prevent="direimburse"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-modal>
                    </b-card>
                </bop-info>
            </b-tab>
            <b-tab :active="LPJTab" title="LPJ BOP">
                <div v-if="bopHasDone">
                    <bop-rincian :bop="bop"
                        @showReimburse="showReimburse = true" :rincian="rincian"
                        @showPengembalian="showPengembalian = true"
                        @regetLaporan="getLaporan"
                        @printLPJ="printLPJ"
                        @changeActiveTab="changeActiveTab"
                    ></bop-rincian>
                </div>
                <b-alert v-else variant="danger" class="p-1" show>
                    <div class="alert-body">
                        <span>Menunggu Persetujuan SPV, Finance, dan Tanda terima dari Sales</span>
                    </div>
                </b-alert>
            </b-tab>
        </b-tabs>
        <!-- Modal Reimburse -->
        <modal-bop :active="showReimburse" label-type="reimburse" label="Reimburse" @submit="saveReimburse" />
        <!-- / -->

        <!-- Modal Pengembalian -->
        <modal-bop :active="showPengembalian" label-type="pengembalian" label="Pengembalian BOP" @submit="savePengembalian" />
        <!-- / -->
    </b-overlay>
</template>
<script>
import {BTabs, BTab, BAlert, BButton, BCard, BOverlay, BModal, BFormGroup, BFormSelect,} from 'bootstrap-vue'
import BopInfo from './components/Info.vue'
import BopRincian from './components/Rincian.vue'
import BopReimburse from './components/Reimburse.vue'
import BopPengembalian from './components/Pengembalian.vue'

export default {
    components: {
        BTabs, BTab, BOverlay, BopInfo, BopRincian, BopReimburse, BopPengembalian, BAlert, BButton, BCard, BModal, BFormGroup, BFormSelect,
        ModalBop: () => import('./Modal.vue')
    },
    computed: {
        sudahSetor() {
            return this.bop.lpj > 0
        },
        bopHasDone() {
            return this.bop && this.bop.appr_terima == 1
        },
        isReimburse() {
            return this.total > (this.bop.nominal || 0)
        },
        isPengembalian() {
            return this.bop.nominal > this.total
        },
        sisa() {
            if(this.isReimburse) {
                return parseInt(this.total) - parseInt(this.bop.nominal)
            }
            return parseInt(this.bop.nominal) - parseInt(this.total)
        },
        total() {
            if(this.rincian.length < 1) {
                return 0
            }

            return this.rincian.reduce((total, list) => total += (parseInt(list.harga) * parseInt(list.qty)), 0)
        }
    },
    data: () => ({
        titleTab: true,
        LPJTab: false,
        bop: null,
        showPengembalian: false,
        showReimburse: false,
        rincian: [],
        isVerticalTab: true,
        loading: false,
        //Bukti
        id_kas: [],
        id_akun:[],
        form: {
            id_kas: null,
            id_akun: null,
        },
        showModalapprfinancebukti: false,
        //Kembali
        form2: {
            id_kas: null,
            id_akun: null,
        },
        // id_kas: [],
        // id_akun:[],
        showModalapprfinance: false,
        //Reimburse
        form3: {
            id_kas: null,
            id_akun: null,
        },
        showModalapprfinance2: false,
    }),
    methods: {
        async dikembali() {
      if (this.$route.params.id) {
        this.form2.id = this.$route.params.id;
      }
      const payload = this.form2;
      try {
        const ajuan = await this.$store.dispatch("bop/dikembali", [payload]);
        if (this.id) {
          this.setCurrentBop();
        } else {
          this.setCurrentBop();
        //   this.resetForm();
          this.displaySuccess({
            message: "Pengembalian BOP telah tersimpan diakun",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
        confirmdireimburse() {
      this.showModalapprfinance2 = true;
      this.getKas();
      this.getakun();
    },
    async direimburse() {
      if (this.$route.params.id) {
        this.form3.id = this.$route.params.id;
      }
      const payload = this.form3;
      try {
        const ajuan = await this.$store.dispatch("bop/direimburse", [payload]);
        if (this.id) {
          this.setCurrentBop();
        } else {
          this.setCurrentBop();
        //   this.resetForm();
          this.displaySuccess({
            message: "Reimburse BOP telah tersimpan diakun",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
        confirmdikembali() {
      this.showModalapprfinance = true;
      this.getKas();
      this.getakun();
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            item.text = item.nama + " - (" + item.saldo + ")";
          });
          this.id_akun = akun;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            item.text = item.nama + " - (" + item.saldo + ")";
          });
          this.id_kas = kas;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
        doLoading(loading) {
            this.loading = loading
        },
        async refreshData() {
            const params = this.isSales ? {order: 'desc', sales_id: this.user.karyawan.id} : {order: 'desc'}
            await this.$store.dispatch('bop/getData', params)
            setTimeout(() => {
                this.setCurrentBop()
                this.getLaporan()
                if(this.isSales) { // ketika approve menerima uang, langsung arahkan untuk buat LPJ
                    this.changeActiveTab('LPJTab')
                }
            }, 1000)    
        },
        changeActiveTab(tabName) {
            if(tabName == 'titleTab') {
                this.titleTab = true
                this.LPJTab = false
            }
            else {
                this.titleTab = false
                this.LPJTab = true
            }
        },
        async printBop() {
            this.loading = true
            const response = await this.$store.dispatch("bop/printAjuan", this.bop.id);
            this.loading = false
            window.open(URL.createObjectURL(response))
        },
        async printSisa(jenis = 'reimburse') {
            try {
                this.loading = true
                const response = await this.$store.dispatch('bop/printSisa', {jenis, bop_id: this.$route.params.id});
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async printLPJ() {
            this.loading = true
            const response = await this.$store.dispatch("bop/printLPJ", this.$route.params.id);
            this.loading = false
            window.open(URL.createObjectURL(response))
        },
        saveReimburse() {},
        savePengembalian() {},
        async getLaporan() {
            const sellingNotes = await this.$store.dispatch('nota-bop/getData', {
                ajuan_id: this.$route.params.id
            })
            
            if(sellingNotes.length > 0) {
                this.rincian = sellingNotes
            }
        },
        checkResponsiveTabs(e) {
            const isTabDevice = window.innerWidth < 800
            if(isTabDevice) {
                this.isVerticalTab = false
            }
            else {
                this.isVerticalTab = true
            }
        },
        setCurrentBop() {
            const bop = this.$store.getters['bop/search'](this.$route.params.id)
            if(!bop) {
                this.$router.push('/bop')
            }
            this.bop = bop
        }
    },
    created() {
        this.setCurrentBop()
        this.getLaporan()
        window.addEventListener("resize", this.checkResponsiveTabs);
    },
    destroyed() {
    window.removeEventListener("resize", this.checkResponsiveTabs);
    }
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>